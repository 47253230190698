/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/index.css"
import "@fontsource/roboto/latin-400.css"
import "@fontsource/roboto/latin-500.css"
